/**
 * [addr.js] 地址相關設定
 * @return
 *
 */

const addrOpts = { // 存放 地址的下拉選單內容
  city: [],
  town: {},
  street: {}
};

// const formInpts = {
//   step: 1,
//   tg: '',
//   id: '',
//   label: '',
//   value: {
//     city: '', // 縣市
//     town: '', // 鄉鎮市區
//     street: '', // 街道路名
//     lane: '', // 巷
//     alley: '', // 弄
//     no: '', // 號
//     floor: '', // 樓層
//     branch: '', // 之
//     room: '', // 室
//     zipcode: '', // 5碼郵遞區號
//     zipcode3: '' // 3碼郵遞區號
//   },
//   rules: {
//     city: [val => !!val || '請輸入縣市'],
//     town: [val => !!val || '請輸入鄉鎮市區'],
//     street: [val => !!val || '請輸入街道路名'],
//     lane: [val => typeof val === 'undefined' || val === '' || /^\d+$/.test(val) || '請輸入巷(數字)'],
//     alley: [val => typeof val === 'undefined' || val === '' || /^\d+$/.test(val) || '請輸入弄(數字)'],
//     no: [val => (val.length > 0 && val.length <= 7) || '請輸入門牌號(最多7碼)'],
//     floor: [val => typeof val === 'undefined' || val === '' || val.length <= 4 || /^\d+$/.test(val) || '請輸入數字(最多4碼)'],
//     branch: [val => typeof val === 'undefined' || val === '' || val.length <= 4 || /^\d+$/.test(val) || '請輸入數字'],
//     room: [val => typeof val === 'undefined' || val === '' || val.length <= 4 || /^\d+$/.test(val) || '請輸入數字(最多4碼)']
//   },
//   type: 'addr',
//   dense: true
// };

const formInpts = {
  city: {
    tg: 'city',
    id: 'city',
    label: '縣市',
    value: '',
    options: [],
    rules: [val => !!val || '請輸入縣市'],
    type: 'select',
    placeholder: '縣市',
    multiple: false,
    outlined: true,
    dense: true,
    smallChips: false,
    hideDetails: 'auto',
    classTitle: 'd-none',
    class: 'mt-2'
    // style: 'max-width: 30rem'
  },
  town: {
    tg: 'town',
    id: 'town',
    label: '鄉鎮市區',
    value: '',
    options: [],
    rules: [val => !!val || '請輸入鄉鎮市區'],
    type: 'select',
    placeholder: '鄉鎮市區',
    multiple: false,
    outlined: true,
    dense: true,
    smallChips: false,
    hideDetails: 'auto',
    classTitle: 'd-none',
    class: 'mt-2'
    // style: 'max-width: 30rem'
  },
  street: {
    tg: 'street',
    id: 'street',
    label: '街道路名',
    value: '',
    options: [],
    rules: [val => !!val || '請輸入街道路名'],
    type: 'select',
    placeholder: '街道路名',
    multiple: false,
    outlined: true,
    dense: true,
    smallChips: false,
    hideDetails: 'auto',
    classTitle: 'd-none',
    class: 'mt-2'
    // style: 'max-width: 30rem'
  },
  lane: {
    tg: 'lane',
    id: 'lane',
    label: '巷',
    value: '',
    rules: [val => typeof val === 'undefined' || val === '' || /^\d+$/.test(val) || '請輸入巷(數字)'],
    type: 'text',
    placeholder: '巷',
    outlined: false,
    dense: true,
    hideDetails: 'auto',
    classTitle: 'd-none',
    class: 'mt-2'
    // style: 'max-width: 30rem'
  },
  alley: {
    tg: 'alley',
    id: 'alley',
    label: '弄',
    value: '',
    rules: [val => typeof val === 'undefined' || val === '' || /^\d+$/.test(val) || '請輸入弄(數字)'],
    type: 'text',
    placeholder: '弄',
    outlined: false,
    dense: true,
    hideDetails: 'auto',
    classTitle: 'd-none',
    class: 'mt-2'
    // style: 'max-width: 30rem'
  },
  no: {
    tg: 'no',
    id: 'no',
    label: '號',
    value: '',
    rules: [val => (!!val && val.length <= 7) || '請輸入門牌號(最多7碼)'],
    type: 'text',
    placeholder: '號',
    outlined: false,
    dense: true,
    hideDetails: 'auto',
    classTitle: 'd-none',
    class: 'mt-2'
    // style: 'max-width: 30rem'
  },
  floor: {
    tg: 'floor',
    id: 'floor',
    label: '樓',
    value: '',
    rules: [val => typeof val === 'undefined' || val === '' || val.length <= 4 || /^\d+$/.test(val) || '請輸入數字(最多4碼)'],
    type: 'text',
    placeholder: '樓',
    outlined: false,
    dense: true,
    hideDetails: 'auto',
    classTitle: 'd-none',
    class: 'mt-2'
    // style: 'max-width: 30rem'
  },
  branch: {
    tg: 'branch',
    id: 'branch',
    label: '之',
    value: '',
    rules: [val => typeof val === 'undefined' || val === '' || val.length <= 4 || /^\d+$/.test(val) || '請輸入數字'],
    type: 'text',
    placeholder: '之',
    outlined: false,
    dense: true,
    hideDetails: 'auto',
    classTitle: 'd-none',
    class: 'mt-2'
    // style: 'max-width: 30rem'
  },
  room: {
    tg: 'room',
    id: 'room',
    label: '室',
    value: '',
    rules: [val => typeof val === 'undefined' || val === '' || val.length <= 4 || /^\d+$/.test(val) || '請輸入數字(最多4碼)'],
    type: 'text',
    placeholder: '室',
    outlined: false,
    dense: true,
    hideDetails: 'auto',
    classTitle: 'd-none',
    class: 'mt-2'
    // style: 'max-width: 30rem'
  },
  zipcode: {
    tg: 'zipcode',
    id: 'zipcode',
    visible: false,
    label: '郵遞區號(5碼)',
    value: '',
    options: [],
    rules: [val => !!val || '請輸入郵遞區號(5碼)'],
    type: 'select',
    placeholder: '請選擇',
    multiple: false,
    outlined: true,
    dense: true,
    smallChips: false,
    hideDetails: 'auto',
    class: 'mt-2'
    // style: 'max-width: 30rem'
  },
  zipcode3: {
    tg: 'zipcode3',
    id: 'zipcode3',
    visible: false,
    label: '郵遞區號(3碼)',
    value: '',
    options: [],
    rules: [val => !!val || '請輸入郵遞區號(3碼)'],
    type: 'select',
    placeholder: '請選擇',
    multiple: false,
    outlined: true,
    dense: true,
    smallChips: false,
    hideDetails: 'auto',
    class: 'mt-2'
    // style: 'max-width: 30rem'
  }
};

const rules = {
  city: [val => !!val || '請輸入縣市'],
  town: [val => !!val || '請輸入鄉鎮市區'],
  street: [val => !!val || '請輸入街道路名'],
  lane: [val => typeof val === 'undefined' || val === '' || /^\d+$/.test(val) || '請輸入巷(數字)'],
  alley: [val => typeof val === 'undefined' || val === '' || /^\d+$/.test(val) || '請輸入弄(數字)'],
  no: [val => (!!val && val.length <= 7) || '請輸入門牌號(最多7碼)'],
  floor: [val => typeof val === 'undefined' || val === '' || val.length <= 4 || /^\d+$/.test(val) || '請輸入數字(最多4碼)'],
  branch: [val => typeof val === 'undefined' || val === '' || val.length <= 4 || /^\d+$/.test(val) || '請輸入數字'],
  room: [val => typeof val === 'undefined' || val === '' || val.length <= 4 || /^\d+$/.test(val) || '請輸入數字(最多4碼)'],
  zipcode: [val => !!val || '請輸入郵遞區號(5碼)'],
  zipcode3: [val => !!val || '請輸入郵遞區號(3碼)']
};

// const formInpts = {
//   step: 1,
//   tg: '',
//   id: '',
//   label: '',
//   classTitle: 'subtitle-2 cyan--text text--darken-3',
//   value: {
//     city: '', // 縣市
//     town: '', // 鄉鎮市區
//     street: '', // 街道路名
//     lane: '', // 巷
//     alley: '', // 弄
//     no: '', // 號
//     floor: '', // 樓層
//     branch: '', // 之
//     room: '', // 室
//     zipcode: '', // 5碼郵遞區號
//     zipcode3: '' // 3碼郵遞區號
//   },
//   rules: {
//     city: [val => !!val || '請輸入縣市'],
//     town: [val => !!val || '請輸入鄉鎮市區'],
//     street: [val => !!val || '請輸入街道路名'],
//     lane: [val => typeof val === 'undefined' || val === '' || /^\d+$/.test(val) || '請輸入數字'],
//     alley: [val => typeof val === 'undefined' || val === '' || /^\d+$/.test(val) || '請輸入弄'],
//     no: [val => (!!val && val.length <= 7) || '請輸入門牌號(最多7碼)'],
//     floor: [val => typeof val === 'undefined' || val === '' || val.length <= 4 || /^\d+$/.test(val) || '請輸入數字(最多4碼)'],
//     branch: [val => typeof val === 'undefined' || val === '' || val.length <= 4 || /^\d+$/.test(val) || '請輸入數字'],
//     room: [val => typeof val === 'undefined' || val === '' || val.length <= 4 || /^\d+$/.test(val) || '請輸入數字(最多4碼)']
//   },
//   options: { city: [], town: [], street: [] }
// };

// const tblfs = {
//     step: 1,
//     tg: '',
//     id: '',
//     label: '',
//     headers: [{
//       value: 'parking_addr',
//       text: '場勘地址',
//       align: '',
//       sortable: false,
//       class: 'cyan--text text--darken-3',
//       width: '',
//       type: 'text',
//       placeholder: '請填裝機車位地址',
//       rules: {
//         city: [val => !!val || '請輸入縣市'],
//         town: [val => !!val || '請輸入鄉鎮市區'],
//         street: [val => !!val || '請輸入街道路名'],
//         lane: [val => typeof val === 'undefined' || val === '' || /^\d+$/.test(val) || '請輸入巷(數字)'],
//         alley: [val => typeof val === 'undefined' || val === '' || /^\d+$/.test(val) || '請輸入弄(數字)'],
//         no: [val => (val.length > 0 && val.length <= 7) || '請輸入門牌號(最多7碼)'],
//         floor: [val => typeof val === 'undefined' || val === '' || val.length <= 4 || /^\d+$/.test(val) || '請輸入數字(最多4碼)'],
//         branch: [val => typeof val === 'undefined' || val === '' || val.length <= 4 || /^\d+$/.test(val) || '請輸入數字'],
//         room: [val => typeof val === 'undefined' || val === '' || val.length <= 4 || /^\d+$/.test(val) || '請輸入數字(最多4碼)']
//       },
//       dense: true
//     },
// },

export default {
  addrOpts: addrOpts,
  formInpts: formInpts,
  rules: rules
};
