/**
 * class_settings 相關 api
 */
import req from './config/https.js';

/**
 * 定義接口
 * 1. apiCsPostQueryFilter 取得分類設定檔 by 進階查詢
 *
 */
// 1.
export const apiCsPostQueryFilter = (params) =>
  req('post', '/api/cs/list', params);
// // 2.
// export const apiMemberPostRegisterVerify = (params) =>
//   req('post', '/api/member/register/verify', params);
// // 1.
// export const apiOAuthPostTokens = (params) =>
//   req('post', '/api/oauth/oauth_token', params);
// // 2.
// export const apiOAuthPostRefreshTokens = (params) =>
//   req('post', '/api/oauth/refresh_token', params);
// // 3.
// export const apiOAuthGetProfile = () => req('get', '/api/profile');
