/**
 * Address 相關 api
 */
import req from './config/https.js';

/**
 * 定義接口
 * 1. apiAddrPostByDivision 取得地址定義檔 by 類別
 *
 */
// 1.
export const apiAddrPostByDivision = (params) =>
  req('post', '/api/addr', params);
// // 1.
// export const apiOAuthPostTokens = (params) =>
//   req('post', '/api/oauth/oauth_token', params);
// // 2.
// export const apiOAuthPostRefreshTokens = (params) =>
//   req('post', '/api/oauth/refresh_token', params);
// // 3.
// export const apiOAuthGetProfile = () => req('get', '/api/profile');
