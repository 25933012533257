/**
 * [comp.js] 公司行號相關設定
 * @return
 *  dialog   對話框預設值，['edit']: 編輯模式
 *  search   搜尋panel
 *  ms       Master / Slave 主檔/明細
 */
import Address from '@setting/addr.js';

const mapping = {
  status: {
    _: { text: '待啟用', class: 'orange--text text--lighten-1' },
    Y: { text: '有效', class: 'primary--text' },
    N: { text: '停用', class: 'lime--text text--darken-2' },
    C: { text: '註銷', class: 'blue-grey--text text--lighten-2' }
  },
  actions: {
    add: '新增',
    edit: '修改',
    delete: '註銷'
  }
};

const dialog = {
  msg: {
    init: false,
    active: false,
    isClose: false, // 關閉訊息窗
    title: '',
    msg: '',
    process: false,
    persistent: true, // 預設要點擊按鈕
    width: '350px',
    btns: {}
  },
  edit: {
    active: false,
    title: 'edit dialog',
    action: '',
    persistent: true,
    maxWidth: '600px',
    toolbarColor: 'blue darken-1',
    btnColor: 'blue darken-1',
    btnText: false, // 按鈕為文字樣式
    btnOutlined: true,
    btns: {
      save: { label: '儲存', enable: false }, // 儲存
      close: { label: '關閉', enable: false } // 關閉
    }
  }
};

const search = { // 進階查詢
  category: {
    tg: 'category',
    id: 'category',
    label: '公司類別',
    value: '', // ['all'],
    options: [], // [{ text: '所有類別', value: 'all' }],
    type: 'select',
    placeholder: '請選擇',
    multiple: false, // true,
    outlined: true,
    dense: true,
    smallChips: true,
    hideDetails: 'auto',
    class: 'mt-2',
    style: 'max-width: 30rem'
  },
  name: {
    tg: 'name',
    id: 'name',
    label: '公司名稱',
    value: '',
    type: 'text',
    placeholder: '公司名稱',
    outlined: true,
    dense: true,
    hideDetails: 'auto',
    class: 'mt-2',
    style: 'max-width: 30rem'
  },
  seo: {
    tg: 'seo',
    id: 'seo',
    label: '關鍵字',
    value: '',
    type: 'text',
    placeholder: '請輸入，多個關鍵字請以空格分隔',
    outlined: true,
    dense: true,
    hideDetails: 'auto',
    class: 'mt-2',
    style: 'max-width: 30rem'
  }
};

const ms = {
  m: { // 主檔
    title: '廠商一覽表',
    value: [], // 選擇列
    rid: -1, // 點擊items的索引值(預設第一列)
    sheetColor: 'light-green lighten-5',
    sheetMaxHeight: '27vh',
    itemsPerPage: 5,
    loading: false,
    loadingText: '處理中，請稍後...',
    noDataText: '查無資料',
    fixedHeight: true, // table's header always show in top and need set height value of table's content area
    height: '20.5vh',
    class: 'elevation-1 table-master', // custom style of table details
    classTrSelect: 'tr-selected',
    // multi-sort start ---
    multiSort: false, // true
    sortBy: [], // "['mod_dt', 'ins_dt']"
    sortDesc: [], // '[true, false]'
    // multi-sort --- end
    actions: {
      edit: { text: '修改' },
      delete: { text: '註銷' }
    },
    headers: [
      { value: 'sno', text: '項次', align: 'center', sortable: false, width: '4rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
      { value: 'id', visible: false, class: 'd-none' },
      { value: 'cht_name', text: '中文名稱', align: 'center', sortable: false, class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
      { value: 'eng_name', text: '英文名稱', align: 'center', sortable: false, class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
      { value: 'short_name', text: '公司簡稱', align: 'center', sortable: false, class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
      { value: 'action', text: '動作', align: 'center', sortable: false, class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
      { value: 'category', visible: false, class: 'd-none' },
      { value: 'category_name', visible: false, class: 'd-none' }, // text: '公司類別', align: 'center', width: '5rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
      { value: 'uniform_number', visible: false, class: 'd-none' }, //  text: '公司統編', align: 'center', width: '6rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
      { value: 'boss', visible: false, class: 'd-none' }, //  text: '公司負責人', align: 'center', width: '6rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
      { value: 'address', visible: false, class: 'd-none' }, //  text: '公司地址', align: 'center', width: '6rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
      { value: 'address_full', visible: false, class: 'd-none' },
      { value: 'contact_name', visible: false, class: 'd-none' }, //  text: '主要聯絡人', align: 'center', width: '6rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
      { value: 'tel_1', visible: false, class: 'd-none' }, //  text: '聯絡人電話-1', align: 'center', width: '8rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
      { value: 'tel_2', visible: false, class: 'd-none' }, //  text: '聯絡人電話-2', align: 'center', width: '8rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
      { value: 'email', visible: false, class: 'd-none' }, //  text: '聯絡人email', align: 'center', width: '8rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
      { value: 'invoice_contact', visible: false, class: 'd-none' }, //  text: '發票聯絡人', align: 'center', width: '6rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
      { value: 'invoice_tel', visible: false, class: 'd-none' }, //  text: '發票聯絡人電話', align: 'center', width: '8rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
      { value: 'invoice_email', visible: false, class: 'd-none' }, //  text: '發票聯絡人email', align: 'center', width: '8rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
      { value: 'invoice_addr', visible: false, class: 'd-none' },
      { value: 'invoice_addr_full', visible: false, class: 'd-none' }, //  text: '發票寄送地址', align: 'center', width: '8rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
      { value: 'status', text: '狀態', align: 'center', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
      { value: 'ins_dt', text: '建檔日期', align: 'center', width: '11rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' },
      { value: 'mod_dt', text: '修改日期', align: 'center', width: '11rem', class: 'body-2 font-weight-black teal lighten-4', classTd: 'text-center td-border-teal-lighten4' }
    ],
    items: []
  },
  d: { // 明細
    tab: null,
    rid: 'profile', // 目前所在頁籤
    iconAndText: true, // tab with icon
    height: '2.5rem', // tab height
    sheetMaxHeight: '35vh',
    items: {
      profile: {
        id: 'profile',
        type: 'grid',
        label: '基本資料',
        loading: false,
        loadingText: '取得公司基本資料中...',
        noDataText: '查無公司基本資料',
        chipStyle: { color: 'teal lighten-4', class: 'mr-2' }, // style of header's v-chip
        headers: [
          // { value: 'sno', text: '項次', cols: 3 },
          { value: 'category', visible: false },
          { value: 'category_name', text: '類別', cols: 4 },
          { value: 'short_name', text: '公司簡稱', cols: 4 },
          { value: 'uniform_number', text: '統一編號', cols: 4 },
          { value: 'cht_name', text: '中文名稱', cols: 4 },
          { value: 'eng_name', text: '英文名稱', cols: 8 },
          { value: 'boss', text: '公司負責人', cols: 4 },
          { value: 'address', visible: false },
          { value: 'address_full', text: '公司地址', cols: 8 },
          { value: 'contact_name', text: '主要聯絡人', cols: 4 },
          { value: 'tel_1', text: '(主要)聯絡人電話-1', cols: 8 },
          { value: 'email', text: '(主要)聯絡人email', cols: 4 },
          { value: 'tel_2', text: '(主要)聯絡人電話-2', cols: 8 },
          { value: 'invoice_contact', text: '(發票)聯絡人', cols: 4 },
          { value: 'invoice_tel', text: '(發票)聯絡人電話', cols: 8 },
          { value: 'invoice_email', text: '(發票)聯絡人email', cols: 4 },
          { value: 'invoice_addr', visible: false },
          { value: 'invoice_addr_full', text: '(發票)寄送地址', cols: 8 }
        ],
        data: {},
        speedDial_enable: true
      },
      user: {
        id: 'user',
        type: 'table',
        label: '使用者',
        itemsPerPage: 5,
        loading: false,
        loadingText: '廠商使用者資料查詢中...',
        noDataText: '查無廠商使用者',
        fixedHeight: true, // table's header always show in top and need set height value of table's content area
        height: '25vh',
        class: 'table-detail', // custom style of table details
        headers: [
          { value: 'sno', text: '項次', align: 'center', sortable: false, width: '4rem', class: 'body-2 font-weight-black teal lighten-4' },
          { value: 'type', text: '身分', align: 'center', sortable: false, class: 'body-2 font-weight-black teal lighten-4' },
          { value: 'mem_uuid', visible: false, class: 'd-none' },
          { value: 'acc_name', text: '姓名', align: 'center', sortable: false, class: 'body-2 font-weight-black teal lighten-4' },
          { value: 'email', text: 'email', align: 'center', sortable: false, class: 'body-2 font-weight-black teal lighten-4' },
          { value: 'company', visible: false, class: 'd-none' },
          { value: 'company_name', text: '公司別', align: 'center', sortable: false, class: 'body-2 font-weight-black teal lighten-4' },
          { value: 'dept', visible: false, class: 'd-none' },
          { value: 'dept_name', text: '部門別', align: 'center', sortable: false, class: 'body-2 font-weight-black teal lighten-4' },
          { value: 'position', visible: false, class: 'd-none' },
          { value: 'position_name', text: '職稱', align: 'center', sortable: false, class: 'body-2 font-weight-black teal lighten-4' },
          { value: 'status', text: '狀態', align: 'center', sortable: false, class: 'body-2 font-weight-black teal lighten-4' },
          { value: 'ins_dt', text: '新增日期', align: 'center', sortable: false, class: 'body-2 font-weight-black teal lighten-4' },
          { value: 'mod_dt', text: '修改日期', align: 'center', sortable: false, class: 'body-2 font-weight-black teal lighten-4' }
        ],
        data: [],
        speedDial_enable: false
      },
      record: {
        id: 'record',
        type: 'table',
        label: '異動紀錄',
        itemsPerPage: 5,
        loading: false,
        loadingText: '取得公司異動紀錄中...',
        noDataText: '查無公司異動紀錄',
        fixedHeight: true, // table's header always show in top and need set height value of table's content area
        height: '25vh',
        class: 'table-detail', // custom style of table details
        headers: [
          { value: 'sno', text: '項次', align: 'left', sortable: false, width: '4rem', class: 'body-2 font-weight-black teal lighten-4' },
          { value: 'type', text: '紀錄類型', align: 'left', sortable: false, class: 'body-2 font-weight-black teal lighten-4' },
          { value: 'logger', text: '操作人', align: 'left', sortable: false, class: 'body-2 font-weight-black teal lighten-4' },
          { value: 'ins_dt', text: '紀錄日期', align: 'left', sortable: false, class: 'body-2 font-weight-black teal lighten-4' }
        ],
        data: [],
        speedDial_enable: false
      }
    }
  }
};

const tblfs = { // table fields
  cht_name: {
    step: 1,
    tg: 'cht_name',
    id: 'cht_name',
    label: '中文名稱',
    value: '',
    rules: [
      val => !!val || '請輸入(最多30個字)',
      val => val.trim().length <= 30 || '不得超過30個字'
    ],
    type: 'text',
    // required: true, // 即 rules: [ !!val || '請輸入']
    // counter: null,
    placeholder: '請輸入',
    dense: true,
    outlined: true
  },
  eng_name: {
    step: 1,
    tg: 'eng_name',
    id: 'eng_name',
    label: '英文名稱',
    value: '',
    rules: [val => val.trim().length <= 50 || '不得超過50個字'],
    type: 'text',
    // required: false,
    // counter: null,
    placeholder: '請輸入',
    dense: true,
    outlined: true
  },
  short_name: {
    step: 1,
    tg: 'short_name',
    id: 'short_name',
    label: '公司簡稱',
    value: '',
    rules: [val => val.trim().length <= 15 || '不得超過15個字'],
    type: 'text',
    // required: false,
    // counter: null,
    placeholder: '請輸入',
    dense: true,
    outlined: true
  },
  category: {
    step: 1,
    tg: 'category',
    id: 'category',
    label: '公司類型',
    value: '',
    rules: [val => !!val || '請選擇'],
    options: [], // [{ text: 'TESAL', value: 'TESLA' }, { text: 'AUDI', value: 'AUDI' }, { text: 'BMW', value: 'BMW' }],
    type: 'select',
    // required: true,
    placeholder: '請選擇'
    // inline: true,
    // dense: true,
    // small_chips: true,
    // class: 'ma-0 ml-sm-3',
    // style: 'max-width: 12rem'
  },
  uniform_number: {
    step: 1,
    tg: 'uniform_number',
    id: 'uniform_number',
    label: '統一編號',
    value: '',
    rules: [
      val => /^\d+$/.test(val) || '請輸入數字(最多10碼)',
      val => val.trim().length <= 10 || '不得超過10碼'
    ],
    type: 'text',
    // required: false,
    // counter: null,
    placeholder: '請輸入',
    dense: true,
    outlined: true
  },
  boss: {
    step: 1,
    tg: 'boss',
    id: 'boss',
    label: '公司負責人',
    value: '',
    rules: [
      val => !!val || '請輸入(最多20個字)',
      val => val.trim().length <= 20 || '不得超過20個字'
    ],
    type: 'text',
    // required: true,
    // counter: null,
    placeholder: '請輸入',
    dense: true,
    outlined: true
  },
  address: {
    step: 1,
    tg: 'address',
    id: 'address',
    label: '公司地址',
    type: 'addr',
    extra_inputs: {}
  },
  contact_name: {
    step: 1,
    tg: 'contact_name',
    id: 'contact_name',
    label: '主要聯絡人',
    value: '',
    rules: [
      val => !!val || '請輸入(最多20個字)',
      val => val.trim().length <= 20 || '不得超過20個字'
    ],
    type: 'text',
    // required: true,
    // counter: null,
    placeholder: '請輸入',
    dense: true,
    outlined: true
  },
  tel_1: {
    step: 1,
    tg: 'tel_1',
    id: 'tel_1',
    label: '(主要)聯絡人電話-1',
    value: '',
    rules: [
      val => !!val || '請輸入(最多20個字)',
      val => val.trim().length <= 20 || '不得超過20個字'
    ],
    type: 'text',
    // required: true,
    placeholder: '請填寫，ex: 0287912288#分機號',
    // counter: null,
    // hideDetails: 'auto',
    dense: true,
    outlined: true
  },
  tel_2: {
    step: 1,
    tg: 'tel_2',
    id: 'tel_2',
    label: '(主要)聯絡人電話-2',
    value: '',
    rules: [val => val.trim().length <= 20 || '最多20個字'],
    type: 'text',
    // required: false,
    placeholder: '請填寫，ex: 0287912288#分機號',
    // counter: null,
    // hideDetails: 'auto',
    dense: true,
    outlined: true
  },
  email: {
    step: 1,
    tg: 'email',
    id: 'email',
    label: '(主要)聯絡人email',
    value: '',
    rules: [
      val => val.trim().length <= 100 || '超過字數限制',
      val => !val || /.+@.+\..+/.test(val) || '請確認email格式是否正確'
    ],
    type: 'text',
    // required: false,
    placeholder: '如: example@abc.com.tw',
    dense: true,
    outlined: false
  },
  invoice_contact: {
    step: 1,
    tg: 'invoice_contact',
    id: 'invoice_contact',
    label: '(發票)聯絡人',
    value: '',
    rules: [
      val => !!val || '請輸入(發票)聯絡人(最多20個字)',
      val => val.trim().length <= 20 || '最多20個字'
    ],
    type: 'text',
    // required: false,
    // counter: null,
    placeholder: '請輸入',
    dense: true,
    outlined: true
  },
  invoice_tel: {
    step: 1,
    tg: 'invoice_tel',
    id: 'invoice_tel',
    label: '(發票)聯絡人電話',
    value: '',
    rules: [val => val.trim().length <= 20 || '最多20個字'],
    type: 'text',
    // required: false,
    placeholder: '請填寫，ex: 0287912288#分機號',
    // counter: null,
    // hideDetails: 'auto',
    dense: true,
    outlined: true
  },
  invoice_email: {
    step: 1,
    tg: 'invoice_email',
    id: 'invoice_email',
    label: '(發票)聯絡人email',
    value: '',
    rules: [
      val => val.trim().length <= 100 || '超過字數限制',
      val => !val || /.+@.+\..+/.test(val) || '請確認email格式是否正確'
    ],
    type: 'text',
    // required: false,
    placeholder: '如: example@abc.com.tw',
    dense: true,
    outlined: false
  },
  invoice_addr: {
    step: 1,
    tg: 'invoice_addr',
    id: 'invoice_addr',
    label: '(發票)寄送地址',
    type: 'addr',
    extra_inputs: {}
  }
};

var rules = {
  cht_name: [
    val => !!val || '請輸入(最多30個字)',
    val => val.trim().length <= 30 || '不得超過30個字'
  ],
  eng_name: [val => val.trim().length <= 50 || '不得超過50個字'],
  short_name: [val => val.trim().length <= 15 || '不得超過15個字'],
  category: [val => !!val || '請選擇'],
  uniform_number: [
    val => /^\d+$/.test(val) || '請輸入數字(最多10碼)',
    val => val.trim().length <= 10 || '不得超過10碼'
  ],
  boss: [
    val => !!val || '請輸入(最多20個字)',
    val => val.trim().length <= 20 || '不得超過20個字'
  ],
  address: [],
  contact_name: [
    val => !!val || '請輸入(最多20個字)',
    val => val.trim().length <= 20 || '不得超過20個字'
  ],
  tel_1: [
    val => !!val || '請輸入(最多20個字)',
    val => val.trim().length <= 20 || '不得超過20個字'
  ],
  tel_2: [val => val.trim().length <= 20 || '最多20個字'],
  email: [
    val => val.trim().length <= 100 || '超過字數限制',
    val => !val || /.+@.+\..+/.test(val) || '請確認email格式是否正確'
  ],
  invoice_contact: [
    val => !!val || '請輸入(發票)聯絡人(最多20個字)',
    val => val.trim().length <= 20 || '最多20個字'
  ],
  invoice_tel: [val => val.trim().length <= 20 || '最多20個字'],
  invoice_email: [
    val => val.trim().length <= 100 || '超過字數限制',
    val => !val || /.+@.+\..+/.test(val) || '請確認email格式是否正確'
  ],
  invoice_addr: []
};

const speedDial = {
  direction: 'bottom',
  fab: false,
  icon: ['mdi-chevron-double-down', 'mdi-chevron-double-up'], // close, open
  fling: false,
  hover: false,
  top: true,
  right: true,
  bottom: false,
  left: false,
  transition: 'slide-y-reverse-transition',
  class: 'mt-0 mr-5',
  subs: {
    // upload: {
    //   id: 'upload',
    //   value: null,
    //   label: '上傳',
    //   icon: ['mdi-cloud-upload-outline', 'mdi-cloud-off-outline'],
    //   enabled: true, /* 是否啟用按鈕 */
    //   rules: [
    //     v => !!v || '請選擇檔案',
    //     v => !v || !(v.size > 2_097_152) || '上傳檔案不可超過 2 MB' // 2 x 1024 x 1024 = 2097152
    //   ],
    //   color: 'indigo',
    //   tooltip: ['上傳', '放棄上傳'],
    //   isCollapse: false, /* 是否已展開 */
    //   accept: 'pdf/*', // 逗號分隔，ex: "image/jpg, image/jpeg, application/pdf"
    //   placeholder: '請選擇檔案',
    //   hideDetails: 'auto'
    // },
    edit: { id: 'edit', label: '編輯', icon: 'mdi-pencil', enabled: true, color: 'green', tooltip: '編輯' }, // 基本資料
    delete: { id: 'delete', label: '刪除', icon: 'mdi-delete', enabled: false, color: 'red', tooltip: '刪除' }
  }
};

function initTblfs () {
  const fs = tblfs;
  Object.keys(fs).forEach(function (k) {
    if (fs[k].type === 'addr') {
      fs[k].extra_inputs = Address.formInpts;
    }
  });
  return fs;
}

export default {
  mapping: mapping,
  dialog: dialog,
  search: search,
  ms: ms,
  tblfs: initTblfs(),
  rules: rules,
  speedDial: speedDial
};
