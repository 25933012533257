<template>
  <v-sheet :color="mVars.sheetColor" rounded-1 class="px-4 py-3">
    <v-container fluid>
      <!-- Master -->
      <v-row>
        <v-col cols="12" class="px-0 pb-0">
          <v-chip color="teal" label text-color="white" class="subtitle-1">{{
            mVars.title
          }}</v-chip>
        </v-col>
        <v-col class="px-0">
          <v-card>
            <v-card-text class="pa-0">
              <v-sheet
                class="overflow-y-auto"
                :max-height="
                  !!mVars.sheetMaxHeight ? mVars.sheetMaxHeight : '27vh'
                "
              >
                <v-responsive>
                  <v-data-table
                    v-model="mVars.value"
                    :headers="mVars.headers"
                    :items="mVars.items"
                    :items-per-page="mVars.itemsPerPage"
                    :multi-sort="!!mVars.multiSort"
                    :sort-by="mVars.sortBy ? mVars.sortBy : []"
                    :sort-desc="mVars.sortDesc ? mVars.sortDesc : []"
                    :loading="mVars.loading"
                    :loading-text="mVars.loadingText"
                    :no-data-text="mVars.noDataText"
                    dense
                    :fixed-header="!!mVars.fixedHeight"
                    :height="!!mVars.height ? mVars.height : '20vh'"
                    :class="!!mVars.class ? mVars.class : ''"
                    @current-items="setFirstRow($event)"
                  >
                    <template v-slot:item="props">
                      <tr
                        :class="
                          props.item['sno'] - 1 === mVars.rid
                            ? mVars.classTrSelect
                            : ''
                        "
                        @click="switchVendor(props.item.sno - 1, props.index)"
                      >
                        <template v-for="(hd, hid) in props.headers">
                          <td
                            :ref="`m_${hd.value}_${props.index}`"
                            :class="hd.classTd != null ? hd.classTd : ''"
                            :key="`r-${hid}`"
                            v-if="hd.visible == null || !!hd.visible"
                          >
                            <template
                              v-if="
                                hd.value === 'action' &&
                                props.item['status'] !== 'C' &&
                                !!mVars.actions
                              "
                            >
                              <v-btn
                                v-for="(v, k) in mVars.actions"
                                :key="`m-btn-${k}`"
                                color="warning"
                                :disabled="
                                  ['_', 'C', 'N'].indexOf(
                                    props.item['status']
                                  ) >= 0
                                    ? true
                                    : false
                                "
                                outlined
                                small
                                class="btn-small"
                                v-text="v.text"
                                @click="clickBtnActions(k, props.index, $event)"
                              >
                              </v-btn>
                            </template>
                            <template v-else-if="hd.value === 'status'">
                              <span
                                :class="
                                  mapping.status[props.item[hd.value]].class
                                "
                                >{{
                                  mapping.status[props.item[hd.value]].text
                                }}</span
                              >
                            </template>
                            <template v-else>{{
                              hd.value === "action"
                                ? ""
                                : hd.value === "sno"
                                ? `${props.index + 1}`
                                : props.item[hd.value]
                            }}</template>
                          </td>
                        </template>
                      </tr>
                    </template>
                  </v-data-table>
                </v-responsive>
              </v-sheet>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Detail -->
      <v-row>
        <v-col cols="12" class="px-0">
          <v-card>
            <v-tabs
              v-model="dVars.tab"
              background-color="teal"
              dark
              :icons-and-text="dVars.iconsAndText"
              show-arrows
              slider-size="5"
              :height="!dVars.height ? false : dVars.height"
              grow
            >
              <v-tab
                class="body-1 font-weight-bold"
                v-for="d in dVars.items"
                :key="`dtab-${d.id}`"
              >
                {{ d.label }}
              </v-tab>
            </v-tabs>
            <v-divider color="teal"></v-divider>
            <v-tabs-items v-model="dVars.tab" touchless>
              <v-tab-item
                v-for="(d, idx) in dVars.items"
                :key="`dtabitem-${d.id}`"
              >
                <v-card>
                  <v-card-text>
                    <v-sheet
                      class="speed-dialog-btns overflow-y-auto"
                      :max-height="
                        !!dVars.sheetMaxHeight ? dVars.sheetMaxHeight : '35vh'
                      "
                    >
                      <v-container fluid class="ma-0 pa-0">
                        <v-row dense class="ma-0">
                          <v-col cols="12" v-if="d.type === 'table'">
                            <v-data-table
                              :headers="d.headers"
                              :items="d.data"
                              :items-per-page="d.itemsPerPage"
                              :loading="d.loading"
                              :loading-text="d.loadingText"
                              :no-data-text="d.noDataText"
                              dense
                              :fixed-header="!!d.fixedHeight"
                              :height="!!d.height ? d.height : '25vh'"
                              :class="!!d.class ? d.class : ''"
                            >
                            </v-data-table>
                          </v-col>
                          <template v-else-if="d.type === 'grid'">
                            <template v-if="Object.keys(d.data).length <= 0">
                              <!-- no data -->
                              <v-col cols="12">{{ d.noDataText }}</v-col>
                            </template>
                            <template v-else-if="!!d.loading">
                              <!-- 載入中 -->
                              <v-progress-linear
                                color="deep-purple accent-4"
                                indeterminate
                                rounded
                                height="6"
                              >
                              </v-progress-linear>
                            </template>
                            <template v-else v-for="d_hd in d.headers">
                              <!-- data -->
                              <v-col
                                cols="12"
                                sm="12"
                                :md="d_hd.cols != null ? d_hd.cols : ''"
                                :key="`d-${idx}-${d_hd.value}`"
                                v-if="d_hd.visible == null || d_hd.visible"
                              >
                                <v-chip
                                  label
                                  :color="
                                    !!d.chipStyle && !!d.chipStyle.color
                                      ? d.chipStyle.color
                                      : ''
                                  "
                                  :class="
                                    !!d.chipStyle && !!d.chipStyle.class
                                      ? d.chipStyle.class
                                      : ''
                                  "
                                  v-text="d_hd.text"
                                ></v-chip
                                >{{
                                  d.data[d_hd.value] ? d.data[d_hd.value] : ""
                                }}
                              </v-col>
                            </template>
                          </template>
                        </v-row>
                      </v-container>
                      <v-speed-dial
                        v-model="d.speedDial.fab"
                        :top="d.speedDial.top"
                        :bottom="d.speedDial.bottom"
                        :right="d.speedDial.right"
                        :left="d.speedDial.left"
                        :direction="d.speedDial.direction"
                        :open-on-hover="d.speedDial.hover"
                        :transition="d.speedDial.transition"
                        :class="d.speedDial.class"
                        v-if="d.speedDial_enable"
                      >
                        <template v-slot:activator>
                          <!-- root btn -->
                          <v-btn
                            v-model="d.speedDial.fab"
                            :id="`btn-scroll-${d.id}`"
                            color="warning"
                            dark
                            fab
                            small
                          >
                            <v-icon v-if="d.speedDial.fab">
                              {{ d.speedDial.icon[1] }}
                            </v-icon>
                            <v-icon v-else>
                              {{ d.speedDial.icon[0] }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <!-- sub btns -->
                        <template v-for="(sd_item, sd_idx) in d.speedDial.subs">
                          <v-tooltip
                            left
                            v-if="!!sd_item.enabled"
                            :key="`subtip-${idx}-${sd_idx}`"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                fab
                                dark
                                small
                                :color="sd_item.color"
                                @click="clickBtnActions(sd_item.id, mVars.rid)"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>{{ sd_item.icon }}</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ sd_item.tooltip }}</span>
                          </v-tooltip>
                        </template>
                      </v-speed-dial>
                    </v-sheet>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
/**
 * [Master / Slave Table] ms/index
 */
import Company from '@setting/comp.js';
import ArrayHandler from '@util/common/arrayHandler.js';
// import SpeedDial from '@component/btn/SpeedDial';
export default {
  name: 'MasterSlave',
  // components: { SpeedDial },
  props: {
    initVars: {
      type: Object,
      default: function () {
        return {
          m: {},
          d: {}
        };
      }
    }
  },
  data () {
    return {
      logger: '',
      msgDialog: JSON.parse(JSON.stringify(Company.dialog.msg)),
      mapping: Company.mapping,
      isChange: { mitems: false },
      mVars: !this.initVars || !this.initVars.m ? {} : JSON.parse(JSON.stringify(this.initVars.m)),
      dVars: !this.initVars || !this.initVars.d ? {} : JSON.parse(JSON.stringify(this.initVars.d))
    };
  },
  watch: {
    /**
     * [監聽] 廠商清單是否變更
     * @備註
     * 1. 首次查詢
     * 2. 全部更新
     * 3. 進階查詢
     */
    'initVars.m.items': {
      handler (newItems) {
        console.log(`[CompList][watch][initVars.m.items] ... mRid: ${this.initVars.m.rid}`);
        console.log(newItems);
        this.$set(this.mVars, 'items', newItems);
        this.isChange.mItems = true;
        // this.queryDetail(0);
      },
      deep: true

    },
    /**
     * [監聽] 是否點擊廠商清單列
     */
    'mVars.rid': {
      handler (mRid) {
        console.log(`[ms/CompList][watch][mVars.rid] ... mRid: ${mRid}`);
        this.queryDetail(mRid);
      }
    }
  },
  methods: {
    /**
     * [setFirstRow] 顯示排序後資料集中的第一筆
     * @param array 目前table顯示的資料列集合
     * 備註:
     * 1. 進階查詢 - 顯示第一列明細
     * 2. 點擊廠商列 - 顯示指定廠商列明細
     * 3. 變更單頁顯示筆數 - 顯示目前已點擊廠商列
     * 4. 修改指定廠商 - 儲存後因rid未變更，要手動觸發重新抓取該廠商明細
     */
    setFirstRow (currItems) {
      console.log(`[ms/CompList][setFirstRow] ...${this.isChange.mItems}`);
      console.log(currItems);
      const _click = { rid: -1, id: -1 };
      if (this.isChange.mItems) { // itemse更新，固定顯示可見範圍的第一列廠商
        _click.id = currItems.length > 0 ? currItems[0].id : -1; // 指定廠商的id
        _click.rid = _click.id >= 0 && this.mVars.items.length > 0 ? ArrayHandler.array_column(this.mVars.items, 'id').indexOf(_click.id) : -1; // items的列索引值
        if (this.mVars.rid !== _click.rid) {
          this.$set(this.mVars, 'rid', _click.rid);
        } else {
          this.queryDetail(_click.rid);
        }
        this.isChange.mItems = false;
        this.$set(this.mVars, 'loading', false);
      }
    },
    /**
     * [clickBtnActions]
     * @param string action 執行項目
     * @param mix rid 索引值
     */
    clickBtnActions (action, rid, evt) {
      // console.log(`[ms/CompList][clickBtnActions] ... action: ${action} / rid: ${rid}`);
      // evt.preventDefault();
      switch (action) {
        case 'delete':
        case 'edit':
          this.$emit('update:modifyCompany', { action: action, mRid: rid });
          break;
      }
    },
    /**
     * [switchVendorRow] 切換顯示廠商列
     * @param int mRid  該點擊列廠商於 mVars.items的索引值
     */
    switchVendor (mRid, idx) {
      console.log(`[ms/CompList][switchVendor] ... mRid: ${mRid} / ${idx}`);
      console.log(this.mVars);
      this.mVars.value = [];
      this.mVars.value.push(this.mVars.items[mRid]);
      this.$set(this.mVars, 'rid', mRid);
    },
    /**
     * [queryDetail] 指定需求單(點擊列，處理顯示明細內容)
     * @param * mRid mVars.items的列索引值
     */
    queryDetail (mRid) {
      // console.log(`[CompList][queryDetail] ... mRid: ${mRid}`);
      // 解析明細
      this.decodeDataSlave(mRid); // 重新解析明細
    },
    /**
     * [decodeDataSlave] 解析廠商資料-slave by master rid
     * @param * mRid  mVars.items的列索引值
     */
    decodeDataSlave (mRid = -1) {
      // if (mRid < 0 || !this.mVars.items) {
      //   return;
      // }
      // console.log(`[CompList][decodeDataSlave] ... mRid: ${mRid}`);

      // default with profile of first master
      const mData = this.mVars.items.legnth <= 0 || !this.mVars.items[mRid] ? {} : this.mVars.items[mRid];
      const _id = 'profile';
      const _list = ArrayHandler.array_column(this.dVars.items[_id].headers, 'value');
      const dData = {};
      if (mData && Object.keys(mData).length > 0) {
        _list.forEach(function (k) {
          dData[k] = mData && mData[k] ? mData[k] : '';
        });
      }
      this.$set(this.dVars.items[_id], 'data', dData);
    }
  },
  created () {
    console.log('[CompList][created]] ...');
    Object.keys(this.dVars.items).forEach(function (k) {
      if (this.dVars.items[k].speedDial_enable) {
        this.$set(this.dVars.items[k], 'speedDial', JSON.parse(JSON.stringify(Company.speedDial)));
      }
    }, this);
  },
  mounted () {
    console.log('[CompList][mounted] ...');
  },
  beforeUpdate () {
    console.log('[CompList][beforeUpdate] ...');
  },
  updated () {
    console.log('[CompList][updated] ...');
    // console.log(this.$refs);
    console.log(this.mVars);
    // console.log(this.dVars);
  }
};
</script>
<style scoped>
.speed-dialog-btns .v-speed-dial {
  position: absolute;
  z-index: 2; /* 與table header 同層 */
}

.speed-dialog-btns .v-btn--floating {
  position: relative;
}
</style>
