<template>
  <v-dialog
    v-model="dialogVars.active"
    :persistent="dialogVars.persistent"
    :max-width="dialogVars.maxWidth"
    scrollable
  >
    <v-form ref="editForm">
      <v-card tile>
        <v-card-title class="pa-0">
          <v-toolbar flat dark dense :color="dialogVars.toolbarColor">
            <v-toolbar-title>{{ dialogVars.title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    text
                    @click="clickBtnActions('close', $event)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>關閉</span>
              </v-tooltip>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider class="divider-normal"></v-divider>
        <v-card-text
          class="my-3 subtitle-1 text-center"
          v-if="action === 'delete'"
          v-html="`廠商： [${compRow.id}] ${compRow.cht_name} <br>確定註銷？`"
        ></v-card-text>
        <v-card-text class="pt-4" v-else>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                v-for="(f, fid) in formVars"
                :key="`${f.type}-${fid}`"
              >
                <v-flex class="d-flex flex-row">
                  <div
                    class="subtitle-1 pt-2 px-2 font-weight-bold align-self-start"
                    :class="f.classTitle != null ? f.classTitle : ''"
                  >
                    {{ f.label }}
                  </div>
                  <!-- text input -->
                  <v-text-field
                    v-if="f.type === 'text'"
                    v-model="formVars[fid].value"
                    :rules="!!f.rules ? f.rules : []"
                    :placeholder="!!f.placeholder ? f.placeholder : ''"
                    :counter="!!f.counter ? f.counter : null"
                    :disabled="!!f.disabled ? f.disabled : false"
                    :dense="!!f.dense ? f.dense : true"
                    :outlined="!!f.outlined ? f.outlined : false"
                    :class="!!f.class ? f.class : ''"
                    :style="!!f.style ? f.style : ''"
                    :hide-details="!!f.hideDetails ? f.hideDetails : 'auto'"
                    :required="!!f.required ? f.required : true"
                  ></v-text-field>
                  <!-- select -->
                  <v-autocomplete
                    v-if="f.type === 'select'"
                    v-model="formVars[fid].value"
                    :items="!!f.options ? f.options : []"
                    :rules="!!f.rules ? f.rules : []"
                    :placeholder="!!f.placeholder ? f.placeholder : ''"
                    :class="!!f.class ? f.class : ''"
                    :style="!!f.style ? f.style : ''"
                    :dense="!!f.dense ? f.dense : true"
                    :small-chips="!!f.smallChips ? f.smallChips : false"
                    :hide-details="!!f.hideDetails ? f.hideDetails : 'auto'"
                    :required="!!f.required ? f.required : false"
                  >
                  </v-autocomplete>
                  <!--addr -->
                  <AddrInput
                    v-if="f.type === 'addr' && dialogVars.active"
                    :target="fid"
                    :initForm="formVars[fid].extra_inputs"
                    :addrOpts="addrOpts"
                    v-on:update:setFormInput="setFormInput"
                  ></AddrInput>
                </v-flex>
              </v-col>
            </v-row>
          </v-container>
          <!-- <small>*indicates required field</small> -->
        </v-card-text>
        <v-divider class="divider-normal"></v-divider>
        <v-card-actions dense class="pt-3 pb-5 justify-center">
          <template v-for="(b, bidx) in dialogVars.btns">
            <v-btn
              :color="
                !!b.color && b.color != '' ? b.color : dialogVars.btnColor
              "
              :text="b.text != null ? b.text : dialogVars.btnText"
              :outlined="
                b.outlined != null ? b.outlined : dialogVars.btnOutlined
              "
              @click.stop="clickBtnActions(bidx, $event)"
              v-if="b.enable"
              :key="`btn-${bidx}`"
              >{{ b.label }}
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { apiCompanyPostStore, apiCompanyPutUpdate, apiCompanyDeleteDestroy } from '@api/company.js';
import Company from '@setting/comp.js';
// import ArrayHandler from '@util/common/arrayHandler.js';
import calAddress from '@util/common/calAddress.js';
// import getCS from '@util/common/getCS.js';
import AddrInput from '@component/form/AddrInput';

export default {
  name: 'EditDialog',
  components: { AddrInput },
  props: {
    target: {
      type: String,
      default: ''
    },
    initDialog: { // dialog
      type: Object,
      default: function () {
        return {
          active: false,
          action: ''
        };
      }
    },
    initForm: { // 編輯欄位
      type: Object,
      default: function () {
        return {
          form: {},
          rules: {},
          item: {} // 指定編輯的廠商資料
        };
      }
    },
    logger: { // 登入者
      type: Object,
      default: function () {
        return {};
      }
    },
    csOptions: { // cs 代碼對照檔
      type: Object,
      default: function () {
        return {
        };
      }
    }
  },
  data () {
    return {
      isSaveClose: false,
      mapping: Company.mapping,
      companyId: null,
      dialogVars: this.initDialog,
      msgDialog: JSON.parse(JSON.stringify(Company.dialog.msg)),
      formVars: {}, // JSON.parse(JSON.stringify(this.initForm.form))
      csOpts: {},
      addrOpts: {
        city: [],
        town: {},
        street: {}
      }
    };
  },
  computed: {
    /**
     * [指定編輯廠商資料]
     */
    compRow: {
      get () {
        return this.initForm.item;
      }
    },
    action: {
      get () {
        return this.initDialog.action;
      }
    }
  },
  watch: {
    csOptions: {
      handler (newCS) {
        this.$set(this, 'csOpts', newCS);
      },
      deep: true
    },
    /**
     * [監聽] dialog 狀態
     */
    'initDialog.active': {
      handler (newActive) {
        console.log(`[editDialog][watch][initDialog.active] ...active: ${newActive} / action: ${this.action}`);
        console.log(this.csOpts);
        console.log(this.isSaveClose);
        // console.log(this.initDialog);
        if (newActive) { // 開啟
          this.initSet();
          if (this.isSaveClose) { // 編輯後儲存後再次開啟要回復初始化的驗證狀態
            this.$refs.editForm.resetValidation();
            this.isSaveClose = false;
          }
          switch (this.action) {
            case 'edit': // 編輯廠商
            case 'delete': // 註銷廠商
              if (this.compRow && Object.keys(this.compRow).length > 0) {
                // console.log(`${this.action}廠商 ...`);
                const _formVars = this.formVars;
                const _item = this.compRow;
                Object.keys(_formVars).forEach(function (k) {
                  if (_formVars[k].type === 'addr') {
                    Object.keys(_formVars[k].extra_inputs).forEach(function (ak) {
                      if (_item[k][ak] != null) {
                        _formVars[k].extra_inputs[ak].value = _item[k][ak];
                      }
                    });
                  } else if (_item[k] != null) {
                    _formVars[k].value = _item[k];
                  }
                }, this);
              }
              // console.log(this.formVars);
              // console.log(this.compRow);
              break;
          }
        } else {
          this.$refs.editForm.resetValidation();
        }
        this.$set(this, 'dialogVars', this.initDialog);
        // console.log(this.dialogVars);
      }
    }
  },
  methods: {
    /**
         * [initSet] 初始化
         */
    initSet () {
      // console.log('[editDialog][initSet] ...');
      const _rules = this.initForm && this.initForm.rules ? this.initForm.rules : {};
      const _formVars = JSON.parse(JSON.stringify(this.initForm.form));
      // const _action = this.initDialog.action != null ? this.initDialog.action : this.$options.data().action;
      const _companyId = this.compRow && this.compRow.id ? this.compRow.id : this.$options.data().companyId;
      // rules / options
      Object.keys(_formVars).forEach(function (k) {
        _formVars[k].rules = _rules[k] ? _rules[k] : [];
        if (this.csOpts[k] != null) {
          _formVars[k].options = this.csOpts[k];
        }
      }, this);
      this.$set(this, 'formVars', _formVars);

      // this.$set(this, 'action', _action);
      this.$set(this, 'companyId', _companyId || this.$options.data().companyId);
    },
    /**
   * [runMsgDialog] MsgDialog from Parents
   */
    runMsgDialog (msg) {
      this.$emit('update:runMsgDialog', msg);
    },
    /**
     * [setFormInput] 子組件 變更 form 輸入值
     */
    setFormInput (param = null) {
      const _tg = param.tg ? param.tg : '';
      const _id = param.id ? param.id : '';
      const _val = param.value ? param.value : '';
      // console.log(`[editDialog][setFormInput] ... tg: ${_tg}，id: ${_id}`);
      // console.log(_val);
      this.$set(this.formVars[_tg], _id, _val);
      // console.log(this.formVars);
    },
    /**
     * [clickBtnActions]
     * @param string tg  目的，[close]: 關閉dialog / [save]: 儲存新增廠商
     */
    clickBtnActions (tg = '') {
      let _param = {};
      switch (tg) {
        case 'close':
          this.dialogVars.active = false;
          break;
        case 'save':
          // console.log(`儲存廠商 - ${this.dialogVars.action}`);
          // console.log(this.dialogVars);
          if (this.$refs.editForm.validate()) {
            _param = {
              caller: process.env.VUE_APP_USER,
              user_uid: this.logger.mem_uuid,
              company: this.decodeFormData()
            };
            // console.log(_param);
            this.modifyCompany(_param);
          }
          break;
        case 'delete':
          // console.log(`註銷廠商 - ${this.dialogVars.action} / ${this.companyId}`);
          _param = {
            caller: process.env.VUE_APP_USER,
            user_uid: this.logger.mem_uuid
          };
          // console.log(_param);
          this.modifyCompany(_param);
          break;
      }
    },
    /**
     * [decodeFormData] 解析輸入資料
     */
    decodeFormData () {
      const data = {};
      const _form = this.formVars;
      let _id = '';
      let _rid = '';
      // const _action = this.action;
      // const _addrList = Object.keys(Address.formInpts);
      Object.keys(_form).forEach(function (k) {
        _id = _form[k].id ? _form[k].id : k;
        if (_form[k].type === 'addr') {
          data[_id] = {};
          if (this.action === 'edit') { // 編輯模式
            data[_id].add_seq = this.compRow[_id].add_seq;
          }
          Object.keys(_form[k].extra_inputs).forEach(function (ak) {
            _rid = _form[k].extra_inputs[ak].id ? _form[k].extra_inputs[ak].id : ak;
            data[_id][_rid] = _form[k].extra_inputs[ak].value;
          });
        } else {
          data[_id] = _form[k].value;
        }
      }, this);

      // 編輯模式
      if (this.action === 'edit') {
        data.id = this.companyId;
      }
      return data;
    },
    /**
   * [modifyCompany] 編輯公司行號主檔
   * @param (*) param
   * @param string tg
   * @param int    rid 指定tab的items索引值
   */
    async modifyCompany (params = null) {
      let _dialog = this.$options.data().msgDialog;
      // const _action = this.action;
      if (!params || !this.mapping.actions[this.action]) {
        _dialog.persistent = false;
        _dialog.msg = '入參不正確';
        this.runMsgDialog(_dialog);
        return false;
      }

      const _errCode = {
        101: `公司行號主檔 - ${this.mapping.actions[this.action]}失敗`,
        102: '公司行號主檔 - 查無資料',
        104: '公司行號主檔 - 項目未定義',
        105: '公司行號主檔 - 查詢發生異常'
      };

      // dialog with progress
      _dialog.process = true;
      _dialog.persistent = true;
      _dialog.title = `公司行號主檔 - ${this.mapping.actions[this.action]} 處理中，請稍後...`;
      this.runMsgDialog(_dialog);

      let fail = {};
      let result = null;
      let _mRid = -1;
      let _modify = '';
      // dialog
      _dialog = this.$options.data().msgDialog;
      // console.log(params);
      try {
        // axios api
        switch (this.action) {
          case 'add':
            _modify = 'add_update';
            result = await apiCompanyPostStore(params);
            break;
          case 'edit':
            _modify = 'edit_update';
            _mRid = this.dialogVars.rid.m;
            result = await apiCompanyPutUpdate(this.companyId, params);
            break;
          case 'delete':
            _modify = 'edit_update';
            _mRid = this.dialogVars.rid.m;
            result = await apiCompanyDeleteDestroy(this.companyId, params);
            break;
          default:
            _dialog.title = _errCode[104];
            _dialog.msg = result.msg;
            _dialog.persistent = false;
            this.runMsgDialog(_dialog);
            return false;
        }
        // console.log(result);
        // 失敗
        if (!result || !result.status) {
          fail = { action: 'error', status: 101, msg: _errCode[101], data: result };
          _dialog.title = _errCode[101];
          _dialog.msg = result.msg;
          _dialog.persistent = false;
          this.runMsgDialog(_dialog);
          return false;
        }
        if (!result.data) {
          fail = { action: 'error', status: 102, msg: _errCode[102], data: result };
          _dialog.title = _errCode[102];
          _dialog.msg = result.msg;
          _dialog.persistent = false;
          this.runMsgDialog(_dialog);
          return false;
        }
        // success
        // console.log(`公司行號：${param.cht_name}，${this.mapping.actions[this.action]} 成功`);
        // console.log(result.data);

        // 關閉 msg dialog
        _dialog.msg = `公司行號：${result.data.cht_name}，${this.mapping.actions[this.action]} 成功`;
        _dialog.persistent = true;
        _dialog.btns.close = true;
        _dialog.init = true;
        // _dialog.isClose = true;
        this.runMsgDialog(_dialog);

        // 更新廠商資料
        if (_modify) {
          this.$emit('update:modifyCompany', { action: _modify, mRid: _mRid, data: result.data });
        }

        // 關閉 edit Dialog
        this.isSaveClose = true;
        this.clickBtnActions('close');
      } catch (error) {
        fail = { action: 'error', status: 105, msg: _errCode[105], data: error };
        console.log(_errCode[105]);
        console.log(fail);
        _dialog.msg = error;
        _dialog.persistent = false;
        this.runMsgDialog(_dialog);
        return false;
      }
    }
  },
  created () {
    console.log('[editDialog][created] ...');
    this.initSet();

    // // 取得分類設定檔清單 for select options
    // getCS.setCsByFilter({ '02': 'category' }, this, 'csOpts');

    // 取得縣市/鄉鎮市區
    calAddress.getCityTown({
      action: '02',
      platform: this.logger !== '_' ? 'backend' : 'frontend',
      logger: this.logger,
      caller: 'ct'
    }, this, 'addrOpts');

    // console.log(this.dialogVars);
    // console.log(this.formVars);
    // console.log(this.addrOpts);
    // console.log(this.csOpts);
  },
  mounted () {
    console.log('[EditDialog][mounted] ... ');
  },
  updated () {
    console.log('[EditDialog][updated] ... ');
  }
};
</script>
