<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title
            style="background-color: #c8e6c9"
            class="title font-weight-bold"
          >
            <v-row>
              <v-col cols>
                <v-avatar size="40" color="teal" class="mr-2">
                  <v-icon middle dark>{{ topic.icon }}</v-icon>
                </v-avatar>
                {{ topic.label }}
              </v-col>
              <v-col cols="auto">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="teal"
                      :disabled="!btns.add"
                      @click.stop="clickBtnActions('add', $event)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>新增廠商</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="teal"
                      @click="switchPanel('search')"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-tune</v-icon>
                    </v-btn>
                  </template>
                  <span>進階查詢</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      ref="refreshBtn"
                      icon
                      color="teal"
                      :disabled="!btns.refresh"
                      @click.stop="clickBtnActions('refresh', $event)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>全部更新</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-title>
          <!-- search Filters -->
          <v-card-text class="ma-0 pa-0">
            <v-expansion-panels v-model="panel.active" accordion flat>
              <v-expansion-panel>
                <v-expansion-panel-content class="myPanelCustom">
                  <v-chip
                    color="success"
                    label
                    small
                    text-color="white"
                    class="subtitle-1"
                    >{{ "查詢條件" }}</v-chip
                  >
                  <template v-for="(s, sid) in searchVars.items">
                    <template v-if="!!s && s.type != null">
                      <v-select
                        v-model="s.value"
                        :label="s.label"
                        :items="s.options"
                        :placeholder="s.placeholder"
                        :multiple="s.multiple"
                        :outlined="s.outlined"
                        :dense="s.dense"
                        :small-chips="s.smallChip"
                        :hide-details="s.hideDetails"
                        :class="s.class"
                        :style="s.style"
                        v-if="s.type === 'select'"
                        :key="`search-${sid}`"
                      ></v-select>
                      <v-text-field
                        v-model="s.value"
                        :label="s.label"
                        :placeholder="s.placeholder"
                        :outlined="s.outlined"
                        :dense="s.dense"
                        :hide-details="s.hideDetails"
                        :class="s.class"
                        :style="s.style"
                        v-else-if="s.type === 'text'"
                        :key="`search-${sid}`"
                      ></v-text-field>
                    </template>
                  </template>
                  <v-btn
                    color="success"
                    outlined
                    small
                    class="mt-2"
                    :disabled="searchVars.disabled"
                    @click.enter.prevent="clickBtnActions('search', $event)"
                    >送出</v-btn
                  >
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-divider v-if="panel.active !== null"></v-divider>
          </v-card-text>
          <!-- master / detail -->
          <v-card-text class="pa-0">
            <!-- Master / Slave -->
            <CompList
              :initVars="{ m: mVars, d: dVars }"
              v-on:update:modifyCompany="modifyCompany"
            ></CompList>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- Msg dialog -->
    <MsgDialog
      :dialogProps="dialog.msg"
      v-on:update:runMsgDialog="runMsgDialog"
    >
      <template v-slot:custom-card-actions>
        <v-btn
          color="green darken-1"
          outlined
          @click="clickBtnActions('delete')"
          v-if="!!dialog.msg.btns.delete"
          >註銷</v-btn
        >
        <v-btn
          color="green darken-1"
          outlined
          @click="dialog.msg.isClose = true"
          v-if="!!dialog.msg.btns.close"
          >關閉</v-btn
        >
      </template>
    </MsgDialog>
    <EditDialog
      :initDialog="dialog.edit"
      :initForm="formVars"
      :logger="logger"
      :csOptions="csOpts"
      v-on:update:runMsgDialog="runMsgDialog"
      v-on:update:modifyCompany="modifyCompany"
    ></EditDialog>
    <!-- <EditDialog
      :initDialog="dialog.edit"
      :initForm="formVars"
      :extraOpts="{ cs: csOpts }"
    ></EditDialog> -->
  </v-container>
</template>
<script>
/**
 * [廠商管理 >> 廠商列表]
 */
import { apiCompanyGetIndex } from '@api/company.js';
import Company from '@setting/comp.js';
// import Address from '@setting/addr.js';
import ArrayHandler from '@util/common/arrayHandler.js';
import LoginVerify from '@util/common/loginVerify.js';
// import calAddress from '@util/common/calAddress.js';
import getCS from '@util/common/getCS.js';
import CompList from '@component/ms/CompList.vue';
import EditDialog from '@component/dialog/EditDialog';
import MsgDialog from '@component/dialog/MsgDialog';

export default {
  name: 'VendorList',
  components: { CompList, EditDialog, MsgDialog },
  data () {
    return {
      logger: {},
      topic: {
        label: '',
        icon: '',
        cs: {
          category: '02',
          cs_code: '21'
        }
      },
      panel: {
        active: null,
        items: ['search']
      },
      btns: {
        add: true,
        refresh: true
      },
      searchVars: { // 進階查詢
        disabled: false,
        items: JSON.parse(JSON.stringify(Company.search))
      },
      csOpts: {},
      // addrOpts: {
      //   city: [],
      //   town: {},
      //   street: {}
      // },
      dialog: { // 對話框
        msg: JSON.parse(JSON.stringify(Company.dialog.msg)),
        edit: { // 編輯框
          ...Company.dialog.edit,
          ...{ // custom
            active: false,
            title: '',
            action: '',
            toolbarColor: 'teal',
            btnColor: 'teal',
            maxWidth: '500px',
            btns: {
              save: { label: '儲存', enable: false }, // 儲存
              delete: { label: '確定註銷', enable: false }, // 註銷
              close: { label: '關閉', enable: false } // 關閉
            }
          }
        }
      },
      formVars: {
        form: Company.tblfs, // getCS.getCsByFilter({ '02': 'category' }, Company.tblfs),
        rules: Company.rules,
        item: {}
      },
      mVars: {
        ...Company.ms.m,
        ...{
          multiSort: true,
          sortBy: ['mod_dt', 'ins_dt'],
          sortDesc: [true, false]
        }
      }, // master
      dVars: Company.ms.d // slave
    };
  },
  watch: {
    /**
     * [監聽] 查詢代碼對照檔
     */
    csOpts: {
      handler (newOpts) {
        const _search = this.searchVars && this.searchVars.items ? this.searchVars.items : {};
        Object.keys(newOpts).forEach(function (k) {
          if (_search[k]) {
            _search[k].options = newOpts[k];
          }
        });
        this.$set(this.searchVars, 'items', _search);
      },
      deep: true
    },
    /**
     * [監聽] 編輯對話框
     * @備註
     * 1. 關閉 編輯對話框，清空指定編輯的廠商資料
     */
    'dialog.edit.active': {
      handler (newActive) {
        // console.log(`[Vendor/list][watch][dialog.edit.active] ... ${newActive}`);
        if (!newActive && this.dialog.edit.action === 'edit') {
          this.formVars.item = {};
        }
      }
    }
  },
  methods: {
    /**
     * [initSet]
     * @param object filters 進階查詢條件
     */
    initSet (filters = null) {
      console.log('[Vendor/list][initSet] ... ');
      // console.log(this.searchVars);
      // 登入者
      this.logger = LoginVerify.getLogger();
      // topic
      this.$set(this.topic, 'label', !this.$route || !this.$route.meta.menuLabel ? '' : this.$route.meta.menuLabel);
      this.$set(this.topic, 'icon', !this.$route || !this.$route.matched[0].meta.icon ? '' : this.$route.matched[0].meta.icon);
      // console.log(this.formVars.form);
      // master 載入中
      this.$set(this.mVars, 'loading', true);

      // 查詢廠商列表
      let _param = {
        caller: process.env.VUE_APP_USER,
        user_uid: this.logger.mem_uuid,
        // 指定公司行號類別
        category: this.topic.cs.cs_code
      };
      // 進階查詢
      if (!!filters && Object.keys(filters).length > 0) {
        _param = { ..._param, ...filters };
      }
      // console.log(_param);
      this.getVendorByFilter(_param);
    },
    /**
     * [switchPanel] 切換開合式選單
     * @param string tg 點擊選單列
     */
    switchPanel (tg) {
      console.log(this.panel);
      console.log(this.$options.data().searchVars.items);
      console.log(this.searchVars.items);
      let _active = this.panel.items.indexOf(tg);
      _active = (_active === null || _active < 0 || this.panel.active === _active) ? null : _active;
      this.panel.active = _active;
      switch (tg) {
        case 'search': // 進階查詢
          if (!_active) { // 關閉時，清除內容
            this.$set(this.searchVars, 'items', this.$options.data().searchVars.items);
          }
          break;
      }
    },
    /**
     * [runMsgDialog] let component can run MsgDialog
     */
    runMsgDialog (param) {
      this.$set(this.dialog, 'msg', param);
    },
    /**
     * [modifyCompany] 子組件觸動父組件
     */
    modifyCompany (param) {
      // console.log('[Vendor/list][modifyCompany] ... ');
      // console.log(param);
      const _action = param.action;
      const _mRid = param.mRid ? param.mRid : -1;
      const _data = param.data ? param.data : null;
      if (_action) {
        switch (_action) {
          case 'add_update': // editDialog 新增完成後重新查詢
            this.clickBtnActions('refresh');
            break;
          case 'edit': // 開啟 editDialog by mRid for edit
            this.clickBtnActions(_action, _mRid);
            break;
          case 'edit_update': // editDialog 儲存後回傳更新資料
            this.$set(this.mVars.items, _mRid, { ...this.mVars.items[_mRid], ..._data });
            break;
          case 'delete': // 開啟 editDialog by mRid for delete
            this.clickBtnActions(_action, _mRid);
            break;
        }
      }
    },
    /**
     * [clickBtnActions]
     * @param string action  點擊按鈕，['add']: 新增廠商 => active editDialog
     *                            ['edit']: 修改廠商  => active editDialog
     *                            ['delete']: 註銷廠商  => active editDialog
     *                            ['search']: 送出-進階查詢
     *                            ['refresh]: 全部更新
     * @param int mRid 指定 廠商一覽表列索引值
     */
    clickBtnActions (action = '', mRid = -1, evt) {
      let _dialog = null;
      let r = null;
      let _search = null;
      switch (action) {
        case 'add': // 新增廠商 => 開啟編輯對話框
          _dialog = this.$options.data().dialog.edit;
          // console.log(`[Vendor/list][clickBtnActions] ... ${action}`);
          // init form
          // this.$set(this, 'formVars.form', this.$options.data().formVars.form);
          // init dialog
          _dialog.active = true;
          _dialog.action = action;
          _dialog.title = `${Company.mapping.actions[action]}廠商`;
          _dialog.btns.save.enable = true;
          _dialog.btns.close.enable = true;
          this.$set(this.dialog, 'edit', _dialog);
          // console.log(this.dialog);
          // console.log(this.formVars.form);
          break;
        case 'delete': // 註銷廠商
        case 'edit': // 修改廠商
          _dialog = this.$options.data().dialog.edit;
          // console.log(`[Vendor/list][clickBtnActions] ... action: ${action}，mRid: ${mRid}`);
          r = this.mVars.items[mRid];
          // console.log(r);
          // console.log(this.formVars);
          _dialog.active = true;
          _dialog.action = action;
          _dialog.title = `${Company.mapping.actions[action]}廠商`;
          _dialog.rid = { m: mRid };
          _dialog.btns.close.enable = true;
          if (action === 'delete') {
            _dialog.maxWidth = '300px';
            _dialog.btns.delete.enable = true;
          } else {
            _dialog.btns.save.enable = true;
          }
          this.formVars.item = r;
          this.$set(this.dialog, 'edit', _dialog);
          break;
        case 'refresh': // 全部更新
          this.$set(this, 'panel', this.$options.data().panel); // reset panel
          this.$set(this, 'searchVars', this.$options.data().searchVars); // reset search
          // // reset tabVars and defaut is current tab
          // _tabVars = this.$options.data().tabVars;
          // _tabVars.tab = this.tabVars.tab;
          // this.$set(this, 'tabVars', _tabVars);
          this.initSet();
          break;
        case 'search': // 進階查詢
          // console.log('[ms/list][search] ...');
          this.searchVars.disabled = true; // for submit btn
          _dialog = this.$options.data().dialog.msg;
          _search = this.searchVars && this.searchVars.items ? this.searchVars.items : {};
          r = {};
          Object.keys(_search).forEach(function (k) {
            if ((typeof _search[k].value === 'string' && _search[k].value !== '') || (Array.isArray(_search[k].value) && _search[k].value.length > 0)) {
              r[k] = _search[k].value;
            }
          });
          // console.log(r);
          // console.log(Object.keys(r).length);
          // 未設定條件
          if (Object.keys(r).length === 0) {
            _dialog.persistent = false;
            _dialog.msg = '請設定篩選條件';
            this.$set(this.dialog, 'msg', _dialog);
            this.$set(this.searchVars, 'disabled', false);
            return;
          }
          // 重新查詢
          this.initSet(r);
          break;
        default:
          alert('click btn with undefined target');
          break;
      }
    },
    /**
     * [getVendorByFilter] 查詢指定條件的廠商資料
     * @param (*) param
     */
    async getVendorByFilter (params = null) {
      console.log('[vendor/list][getVendorByFilter] ... ');
      let _dialog = this.$options.data().dialog.msg;
      if (!params || params.caller == null || params.category == null) {
        this.$set(this.mVars, 'loading', false);
        _dialog.persistent = false;
        _dialog.msg = '入參不正確';
        this.$set(this.dialog, 'msg', _dialog);
        return false;
      }

      const _errCode = {
        101: '取得廠商失敗',
        102: '查無資料',
        105: '取得廠商發生異常'
      };

      // dialog with progress
      _dialog.process = true;
      _dialog.persistent = true;
      _dialog.title = '取得廠商資料，請稍後...';
      this.$set(this.dialog, 'msg', _dialog);

      let fail = {};
      let result = null;
      // dialog
      _dialog = this.$options.data().dialog.msg;
      console.log(params);
      try {
        // axios api
        result = await apiCompanyGetIndex(params);
        console.log(result);
        this.searchVars.disabled = false;
        this.$set(this.mVars, 'loading', false);

        // disabled loading
        // this.tabVars.items[rid].loading = false;
        // 失敗
        if (!result || !result.status) {
          fail = { action: 'error', status: 101, msg: _errCode[101], data: result };
          _dialog.title = _errCode[101];
          _dialog.msg = result.msg;
          _dialog.persistent = false;
          this.$set(this.dialog, 'msg', _dialog);
          return false;
        }
        if (!result.data) {
          fail = { action: 'error', status: 102, msg: _errCode[102], data: result };
          _dialog.title = _errCode[102];
          _dialog.msg = result.msg;
          _dialog.persistent = false;
          this.$set(this.dialog, 'msg', _dialog);
          return false;
        }
        // success
        // console.log(`取得需求單成功[${tg}]`);
        // console.log(result.data);
        this.decodeDataMaster(result.data);
        // 關閉 search panel
        this.panel.active = null;
        // 關閉 msg dialog
        _dialog.isClose = true;
        this.$set(this.dialog, 'msg', _dialog);
      } catch (error) {
        fail = { action: 'error', status: 105, msg: _errCode[105], data: error };
        console.log(_errCode[105]);
        console.log(fail);
        _dialog.msg = error;
        _dialog.persistent = false;
        this.$set(this.dialog, 'msg', _dialog);
        return false;
      }
    },
    /**
 * [decodeDataMaster] 解析廠商資料-master
 */
    decodeDataMaster (data = null) {
      if (!data) {
        return;
      }
      // master
      const _list = ArrayHandler.array_column(this.mVars.headers, 'value');
      this.mVars.items = data.map(function (r) {
        const _item = {};
        _list.forEach(function (k) {
          _item[k] = r[k];
        });
        return _item;
      });
    }
  },
  created () {
    // console.log('[VendorList][created] ...');
    // 初始化
    this.initSet();

    // 取得分類設定檔清單 for select options
    getCS.setCsByFilter({ '02': 'category' }, this, 'csOpts');

    // // 取得縣市/鄉鎮市區
    // calAddress.getCityTown({
    //   action: '02',
    //   platform: this.logger !== '_' ? 'backend' : 'frontend',
    //   logger: this.logger,
    //   caller: 'ct'
    // }, this, 'addrOpts');
    // console.log(Company.rules);
  },
  mounted () {
    // console.log('[VendorList][mounted] ...');
  },
  updated () {
    console.log('[VendorList][updated] ...');
    // console.log(this.formVars);
    console.log(this.csOpts);
  }
};
</script>
