/**
 * Company 相關 api
 */
import req from './config/https.js';

/**
 * 定義接口
 * 1. apiCompanyGetIndex 公司行號主檔-CRUD-index
 * 2. apiCompanyPostStore 公司行號主檔-CRUD-store
 * 3. apiCompanyPutUpdate 公司行號主檔-CRUD-update
 * 4. apiCompanyDeleteDestroy 公司行號主檔-CRUD-destroy
 *
 */
// 1.
export const apiCompanyGetIndex = function (params) {
  return req('get', '/api/comp', params);
};
// 2.
export const apiCompanyPostStore = function (params) {
  return req('post', '/api/comp', params);
};
// 3. [id]: company id
export const apiCompanyPutUpdate = function (id, params) {
  return req('put', `/api/comp/${id}`, params);
};
// 4. [id]: company id
export const apiCompanyDeleteDestroy = function (id, params) {
  return req('delete', `/api/comp/${id}`, params);
};
