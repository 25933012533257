/**
 * getCS.js
 * 分類設定檔相關
 */
import { apiCsPostQueryFilter } from '@api/cs.js';

export default {
  /**
   * [getCsByFilter]
   * @param Object _lists
   * @param Object _this
   * Sample:
   *  { 30: 'parking_type', 31: 'parking_env', 32: 'parking_model', 33: 'community' }
   */
  async getCsByFilter (_lists, _this, _fKey = 'options') {
    // console.log('[getCS][getCsByFilter] ... ');
    // console.log(_lists);
    const _errCode = {
      101: '取得分類設定檔失敗',
      102: '查無資料',
      105: '取得分類設定檔程序發生異常'
    };

    const param = { where_in: [{ cs_catalog: Object.keys(_lists) }] };
    // axios api
    const result = await apiCsPostQueryFilter(param);
    // console.log(result);
    // 失敗
    if (!result || !result.status) {
      return { action: 'error', status: 101, msg: _errCode[101], data: result };
    }
    if (!result.data) {
      return { action: 'error', status: 102, msg: _errCode[102], data: result };
    }
    // console.log(result.data);
    // 成功
    let i = 0;
    let _id = '';
    let _fid = '';
    let r = null;
    const _keys = Object.keys(_lists);
    // reset
    for (i = 0; i < _keys.length; i++) {
      _id = _keys[i];
      _fid = _lists[_id];
      if (_fKey === '') {
        _this[_fid] = _fid === 'community' ? [{ text: '自行新增', value: 'add' }] : [];
      } else {
        _this[_fid][_fKey] = _fid === 'community' ? [{ text: '自行新增', value: 'add' }] : [];
      }
    }
    // push
    for (let i = 0; i < result.data.length; i++) {
      r = result.data[i];
      _fid = _lists[r.cs_catalog];
      if (_fid != null) {
        if (_fKey === '') {
          _this[_fid].push({ text: r.cs_name, value: r.cs_code, up_cs_seq: r.up_cs_seq });
        } else {
          _this[_fid][_fKey].push({ text: r.cs_name, value: r.cs_code, up_cs_seq: r.up_cs_seq });
        }
      }
    }
    // console.log(_this);
  },

  /**
   * [setCsByFilter]
   * @param Object _lists
   * @param Object _this
   * Sample:
   *  { 30: 'parking_type', 31: 'parking_env', 32: 'parking_model', 33: 'community' }
   */
  async setCsByFilter (_lists = null, _this = null, _tg = '') {
    if (!_lists || !_this || !_tg) {
      return false;
    }
    // console.log('[getCS][getCsByFilter] ... ');
    // console.log(_lists);
    // const _errCode = {
    //   101: '取得分類設定檔失敗',
    //   102: '查無資料',
    //   105: '取得分類設定檔程序發生異常'
    // };

    const param = { where_in: [{ cs_catalog: Object.keys(_lists) }] };
    // axios api
    const result = await apiCsPostQueryFilter(param);
    // console.log(result);
    // 失敗
    if (!result || !result.status) {
      return false; // { action: 'error', status: 101, msg: _errCode[101], data: result };
    }
    if (!result.data) {
      return false; // { action: 'error', status: 102, msg: _errCode[102], data: result };
    }

    // 成功
    let i = 0;
    let _id = '';
    let _fid = '';
    const _final = {};
    let r = null;
    const _keys = Object.keys(_lists);

    // 初始化
    for (i = 0; i < _keys.length; i++) {
      _id = _keys[i];
      _fid = _lists[_id];
      _final[_fid] = [];
    }
    // values
    for (i = 0; i < result.data.length; i++) {
      r = result.data[i];
      _fid = _lists[r.cs_catalog];
      if (!!_fid && !!_final[_fid]) {
        _final[_fid].push({ text: r.cs_name, value: r.cs_code, up_cs_seq: r.up_cs_seq });
      }
    }
    _this[_tg] = _final;
    // console.log(_this[_tg]);
  }
};
