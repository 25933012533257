<template>
  <v-container class="pa-0" v-if="Object.keys(formVars).length > 0">
    <v-row dense>
      <template v-for="(a, aid) in formVars">
        <v-col
          cols="12"
          sm="12"
          :key="`col-${target}-${aid}`"
          v-if="a.visible == null || !!a.visible"
        >
          <v-flex class="d-flex flex-row">
            <div :class="!!a.classTitle ? a.classTitle : ''">
              {{ a.label }}
            </div>
          </v-flex>
          <v-text-field
            v-if="a.type === 'text'"
            v-model="formVars[aid].value"
            :label="!!a.label ? a.label : ''"
            :placeholder="!!a.placeholder ? a.placeholder : ''"
            :counter="!!a.counter ? a.counter : null"
            :rules="!!a.rules ? a.rules : []"
            :disabled="!!a.disabled ? a.disabled : false"
            :dense="!!a.dense ? a.dense : true"
            :outlined="!!a.outlined ? a.outlined : false"
            :class="!!a.class ? a.class : ''"
            :style="!!a.style ? a.style : ''"
            :hide-details="!!a.hideDetails ? a.hideDetails : 'auto'"
            :required="!!a.required ? a.required : true"
          ></v-text-field>
          <v-autocomplete
            v-if="a.type === 'select'"
            v-model="formVars[aid].value"
            :items="
              aid === 'city'
                ? citys
                : aid === 'town'
                ? towns
                : aid === 'street'
                ? streets
                : !!a.options
                ? a.options
                : []
            "
            :label="!!a.label ? a.label : ''"
            :rules="!!a.rules ? a.rules : []"
            :placeholder="!!a.placeholder ? a.placeholder : ''"
            :class="!!a.class ? a.class : ''"
            :style="!!a.style ? a.style : ''"
            :dense="!!a.dense ? a.dense : true"
            :small-chips="!!a.smallChips ? a.smallChips : false"
            :hide-details="!!a.hideDetails ? a.hideDetails : 'auto'"
            :required="!!a.required ? a.required : false"
          >
          </v-autocomplete>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>
<script>
import Address from '@setting/addr.js';
import calAddress from '@util/common/calAddress.js';

export default {
  name: 'AddrInput',
  props: {
    target: {
      type: String,
      default: ''
    },
    initForm: { // 編輯欄位
      type: Object,
      default: function () {
        return {};
      }
    },
    addrOpts: { // city-town-street 清單
      type: Object,
      default: function () {
        return {
          //   city: [],
          //   town: {},
          //   street: {}
        };
      }
    }
  },
  data () {
    return {
      tg: this.target,
      formVars: {}
    };
  },
  computed: {
    /**
     * [完整地址]
     */
    fullAddr () {
      const _keys = Object.keys(this.formVars);
      let k = '';
      let _addr = '';
      const _prefix = '_';
      for (let i = 0; i < _keys.length; i++) {
        k = _keys[i];
        _addr = _addr + (i > 0 ? _prefix : '') + this.formVars[k].value;
      }
      return _addr;
    },
    citys: {
      get () {
        // console.log('[AddrInput][computed-get][citys] ...');
        const _list = [];
        if (!!this.addrOpts && !!this.addrOpts.city && this.addrOpts.city.length > 0) {
          this.addrOpts.city.forEach(function (r) {
            _list.push({ text: r, value: r });
          });
        }
        return _list;
      }
    },
    towns: {
      get () {
        const _list = [];
        const _city = this.formVars.city.value;
        // console.log(`[AddrInput][computed][towns-get] ...city: ${_city}`);
        if (!!_city && _city !== '' && !!this.addrOpts && !!this.addrOpts.town && !!this.addrOpts.town[_city] && this.addrOpts.town[_city].length > 0) {
          this.addrOpts.town[_city].forEach(function (r) {
            _list.push({ text: r, value: r });
          });
        }
        return _list;
      },
      set (_town) {
        const _city = this.formVars.city.value;
        // console.log(`[AddrInput][computed][towns-set] ...city: ${_city}，town: ${_town}`);
        // // 取得街道路名 by city-town (沒查過才查)
        if (!this.addrOpts.street[`${_city}_${_town}`] || Object.keys(this.addrOpts.street[`${_city}_${_town}`]).length <= 0) {
          console.log('[AddrInput][computed-set][towns] ... setStreetById');
          calAddress.setStreetById({
            action: '02',
            platform: 'frontend',
            logger: '_',
            caller: 'sz',
            city: _city,
            town: _town
          }, this, 'streets'); // call and return
        }
      }
    },
    streets: {
      get () {
        // console.log('[AddrInput][computed-get][streets] ...');
        const _list = [];
        const _city = this.formVars.city.value;
        const _town = this.formVars.town.value;
        const _sKey = `${_city}_${_town}`;
        const _keys = !!this.addrOpts && !!this.addrOpts.street ? Object.keys(this.addrOpts.street) : [];
        if (!!_city && _city !== '' && !!_town && _town !== '') {
          if (_keys.length > 0 && !!this.addrOpts.street[_sKey]) {
            Object.keys(this.addrOpts.street[_sKey]).forEach(function (_street) {
              _list.push({ text: _street, value: _street });
            });
          }
        }
        return _list;
      },
      set (val) {
        // console.log('[AddrInput][computed-set][streets] ...');
        this.$set(this.addrOpts, 'street', { ...this.addrOpts.street, ...val }); // 引用類型直接變更
      }
    }
  },
  watch: {
    /**
       * [監聽] 是否變更答案 - 縣市
       * @備註:
       * 1. 連動選項 - 鄉鎮市區
       */
    'formVars.city.value': {
      handler (newCity) {
        // console.log(`[AddrInput][watch][city] ... tg: ${this.tg}，city: ${newCity}`);
        this.$set(this.formVars.town, 'options', this.towns);
      }
    },
    /**
       * [監聽] 是否變更答案 - 鄉鎮市區
       * @備註:
       * 1. 連動選項 - 街道路名
       */
    'formVars.town.value': {
      handler (newTown) {
        this.towns = newTown;
      }
    },
    /**
     * [監聽] 是否變更地址
     */
    fullAddr: {
      handler (newVal) {
        console.log(`[AddrInput][watch][fullAddr] ... tg: ${this.tg}，${newVal}`);
        this.$emit('update:setFormInput', { tg: this.tg, id: 'extra_inputs', value: this.formVars });
      }
    }
  },
  methods: {
    /**
       * [initSet] 初始化
       */
    initSet () {
      // console.log(`[AddrInput][initSet] ... tg: ${this.tg}`);
      const _rules = Address.rules ? Address.rules : {};
      const _formVars = JSON.parse(JSON.stringify({ ...Address.formInpts, ...this.initForm })); // for independent data with component;
      // rules
      Object.keys(_formVars).forEach(function (k) {
        _formVars[k].rules = _rules[k] ? _rules[k] : [];
      }, this);
      this.$set(this, 'formVars', _formVars);
    }
  },
  created () {
    console.log(`[AddrInput][created] ... tg: ${this.tg}`);
    this.initSet();
    console.log(this.formVars);
  },
  mounted () {
    console.log(`[AddrInput][mounted] ... tg: ${this.tg}`);
  },
  updated () {
    console.log(`[AddrInput][updated] ... tg: ${this.tg}`);
  }
};
</script>
