var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (Object.keys(_vm.formVars).length > 0)?_c('v-container',{staticClass:"pa-0"},[_c('v-row',{attrs:{"dense":""}},[_vm._l((_vm.formVars),function(a,aid){return [(a.visible == null || !!a.visible)?_c('v-col',{key:("col-" + _vm.target + "-" + aid),attrs:{"cols":"12","sm":"12"}},[_c('v-flex',{staticClass:"d-flex flex-row"},[_c('div',{class:!!a.classTitle ? a.classTitle : ''},[_vm._v(" "+_vm._s(a.label)+" ")])]),(a.type === 'text')?_c('v-text-field',{class:!!a.class ? a.class : '',style:(!!a.style ? a.style : ''),attrs:{"label":!!a.label ? a.label : '',"placeholder":!!a.placeholder ? a.placeholder : '',"counter":!!a.counter ? a.counter : null,"rules":!!a.rules ? a.rules : [],"disabled":!!a.disabled ? a.disabled : false,"dense":!!a.dense ? a.dense : true,"outlined":!!a.outlined ? a.outlined : false,"hide-details":!!a.hideDetails ? a.hideDetails : 'auto',"required":!!a.required ? a.required : true},model:{value:(_vm.formVars[aid].value),callback:function ($$v) {_vm.$set(_vm.formVars[aid], "value", $$v)},expression:"formVars[aid].value"}}):_vm._e(),(a.type === 'select')?_c('v-autocomplete',{class:!!a.class ? a.class : '',style:(!!a.style ? a.style : ''),attrs:{"items":aid === 'city'
              ? _vm.citys
              : aid === 'town'
              ? _vm.towns
              : aid === 'street'
              ? _vm.streets
              : !!a.options
              ? a.options
              : [],"label":!!a.label ? a.label : '',"rules":!!a.rules ? a.rules : [],"placeholder":!!a.placeholder ? a.placeholder : '',"dense":!!a.dense ? a.dense : true,"small-chips":!!a.smallChips ? a.smallChips : false,"hide-details":!!a.hideDetails ? a.hideDetails : 'auto',"required":!!a.required ? a.required : false},model:{value:(_vm.formVars[aid].value),callback:function ($$v) {_vm.$set(_vm.formVars[aid], "value", $$v)},expression:"formVars[aid].value"}}):_vm._e()],1):_vm._e()]})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }